/*
 * IMPORTS
 */
import React from 'react' // NPM: React.js library.
import _ from 'underscore' // NPM: Underscore.js library for  functional suspects (each, map, reduce, filter...) .
import { client, urlFor } from '../../client' // Helper from sanity.io for data.

/*
 * STYLES
 */
import './index.scss'


/*
 * COMPONENTS
 */
import {} from '../../components'

/*
 * CONSTANTS
 */

import { AppWrap, MotionWrap } from '../../wrapper'
import { images } from '../../constants'

/*
 * OBJECTS
 */

const Index = () => {
  // Hook Assignment
  const [incorporationData, setIncorporationData] = React.useState([])

  // UseEffect
  React.useEffect(() => {
    const query = '*[_type == "incorporation_aboutUs"]'
    client.fetch(query).then(data => setIncorporationData(data))
  }, [])

  return (
    <div className='app__main-incorporation'>
      <div className='incorporation-headingWrapper'>
        <div className='heading'>
                Incorporation of the company
        </div>
        <div className='main_content'>
          {incorporationData[0]?.content}
        </div>
      </div>
      <div className='app__main-iso'>
        <div className='iso-headingWrapper'>
          <div className='heading'>
                    ISO Certification
          </div>
          <div className='content'>
            {incorporationData[0]?.isoContent}
          </div>
        </div>
        <div style={{ 'background': `url(${!_.isEmpty(incorporationData) ? urlFor(incorporationData[0]?.iso_image) : images.Placeholder})` }} className='iso-image'/>
      </div>
    </div>
  )
}

/*
 * EXPORTS
 */
export default AppWrap(
  MotionWrap(Index, 'app__about'),
  'home'
)
