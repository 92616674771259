/*
 * IMPORTS
 */
import React from 'react'

/*
 * OBJECTS
 */
const AppWrap = (Component, idName) => function HOC() {
  return (
    <div id={idName} >
      <div >
        <Component/>
      </div>
    </div>
  )
}

/*
 * EXPORTS
 */
export default AppWrap
