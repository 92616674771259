/*
 * IMPORTS
 */
import React from 'react' // NPM: React.js library.
import _ from 'underscore' // NPM: Underscore.js library for  functional suspects (each, map, reduce, filter...) .
import { client, urlFor } from '../../client' // Helper from sanity.io for data.
import ReactReadMoreReadLess from 'react-read-more-read-less' // NPM: characters manipulation package from react-read-more-read-less.
/*
 * STYLES
 */
import './index.scss'


/*
 * COMPONENTS
 */
import { Conclusion, TableRender } from '../../components'

/*
 * CONSTANTS
 */

import { AppWrap, MotionWrap } from '../../wrapper'
import { images } from '../../constants'


/*
 * OBJECTS
 */
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
}

const Index = () => {
  // Hook Assignment
  const [projectBackgroundData, setProjectBackgroundData] = React.useState([])
  const [projectsData, setProjectsData] = React.useState([])

  // UseEffect
  React.useEffect(() => {
    const query = '*[_type == "project_background"]'
    const queryProject = '*[_type == "projects"]'
    client.fetch(query).then(data => setProjectBackgroundData(data))
    client.fetch(queryProject).then(data => setProjectsData(data))
  }, [])


  return (
    <>
      <div className='about__Main'>
        <div className='about__main-homepage' style={{ 'background': `url(${!_.isEmpty(projectBackgroundData) ? urlFor(projectBackgroundData[0]?.background) : images.Placeholder})` }}>
          <div className='headingMainBackground'>{projectBackgroundData[0]?.heading}</div>
          <div className='contentMainBackground'>{projectBackgroundData[0]?.content}</div>
        </div>

        {
          projectsData.map(({ project_type, extra_comment, projects_info }) => (
            <>
              <div className='main__card'>
                <div className='heading'>{project_type}</div>
                <div className='subHeading'>{extra_comment}</div>
                <div className='main__card-Wrapper'>
                  {
                    projects_info.map(({ description, completion_time, projectImage, _key, agency, status }) => (
                      <div key={_key} className='card-wrapper'>
                        <div className='project__image' style={{ 'background': `url(${urlFor(projectImage ? projectImage : images.Placeholder)}` }}/>
                        <div className='description_heading'>Description</div>
                        <div className='project__description'>
                          {
                            '49' === description.length ? { description } : <ReactReadMoreReadLess
                              charLimit={50}
                              readMoreText={'Read more ▼'}
                              readLessText={'Read less ▲'}
                              readMoreClassName='read-more-less--more'
                              readLessClassName='read-more-less--less'
                            >
                              { description }
                            </ReactReadMoreReadLess>
                          }

                        </div>
                        <div className='company_heading'>Company</div>
                        <div className='company'>{
                          '49' === description.length ? { agency } : <ReactReadMoreReadLess
                            charLimit={50}
                            readMoreText={'Read more ▼'}
                            readLessText={'Read less ▲'}
                            readMoreClassName='read-more-less--more--agency'
                            readLessClassName='read-more-less--less--agency'
                          >
                            { agency }
                          </ReactReadMoreReadLess>
                        }</div>
                        <div className='tag'>
                          <div className='project__year'>{completion_time}</div>
                          <div className='status'>{status}</div>
                        </div>
                      </div>
                    ))

                  }
                </div>
              </div>
              <div className='indicator__wrapper'>
                <svg className='arrows'>
                  <path className='a1' d='M0 0 L30 32 L60 0'></path>
                  <path className='a2' d='M0 20 L30 52 L60 20'></path>
                  <path className='a3' d='M0 40 L30 72 L60 40'></path>
                </svg>
              </div>
              <hr className='sep-3'/>
            </>
          ))

        }
      </div>
    </>
  )
}

/*
 * EXPORTS
 */
export default AppWrap(
  MotionWrap(Index, 'app__about'),
  'home'
)
