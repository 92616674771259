/*
 * IMPORTS
 */
import bwLogo from '../assets/logo_black&white.png'
import bgService from '../assets/bgService.png'
import Service from '../assets/services.png'
import ServiceIcon from '../assets/serviceIcon.png'
import Train from '../assets/train.png'
import Placeholder from '../assets/placeholder.png'
import Project from '../assets/project.png'
import EmailIcon from '../assets/emailIcon.png'
import LocationIcon from '../assets/locationIcon.png'
import PhoneIcon from '../assets/phoneIcon.png'

/*
 * EXPORTS
 */

export default {

  bwLogo,
  bgService,
  Service,
  ServiceIcon,
  Train,
  Placeholder,
  Project,
  EmailIcon,
  PhoneIcon,
  LocationIcon

}
