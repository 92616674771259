/*
 * IMPORTS
 */
import React from 'react' // NPM: React.js library.
import { client, urlFor } from '../../client' // Helper from sanity.io for data.

/*
 * STYLES
 */
import './index.scss'


/*
 * WRAPPER
 */

import { AppWrap, MotionWrap } from '../../wrapper'
import { images } from '../../constants'

/*
 * OBJECTS
 */

const Index = () => {
  // Hook Assignment
  const [teamData, setTeamData] = React.useState([])

  // UseEffect
  React.useEffect(() => {
    const query = '*[_type == "about_us"]'
    client.fetch(query).then(data => setTeamData(data))
  }, [])

  return (
    <div className='meet__main'>
      <div className='meet__main-heading' >
      Meet Our Best Team
      </div>
      <div className='meet__main-teamWrapper'>
        {
          teamData[0]?.teamMembers.map(({ _key, memberProfileImage, name, profession }) => (
            <div className='meet__main-backgroundBlack' key={_key} >
              <div style={{ 'background': `url(${memberProfileImage ? urlFor(memberProfileImage) : images.Placeholder})` }} className='meet__main-team'>
                <div className='meet__main-teamWorker-name'>{name}~</div>
                <div className='meet__main-teamWorker-profession'>{profession}</div>
              </div>
            </div>
          ))

        }

      </div>
    </div>
  )
}

/*
 * EXPORTS
 */
export default AppWrap(
  MotionWrap(Index, 'app__meet_team'),
  'meet_team'
)
