/*
 * IMPORTS
 */
import sanityClient from '@sanity/client' // NPM: client from sanity.io.
import imageUrlBuilder from '@sanity/image-url' // NPM: client from image-url.

/*
 * EXPORTS
 */
export const client = sanityClient({
  projectId: process.env.REACT_APP_SANITY_PROJECT_ID,
  dataset: 'production',
  apiVersion: '2022-02-01',
  useCdn: true
})

const builder = imageUrlBuilder(client)



/*
 * EXPORTS
 */
export const urlFor = source => builder.image(source)
