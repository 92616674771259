/*
 * IMPORTS
 */
import _ from 'lodash' // NPM: A modern JavaScript utility library delivering modularity, performance & extras.
import React from 'react' // NPM: React.js library.
import { Link } from 'react-router-dom' // NPM: Link from react-router-dom library.
import { client, urlFor } from '../../client' // Helper from sanity.io for data.


/*
 * STYLES
 */
import './index.scss'

/*
 * OBJECTS
 */
const Index = ({ path }) => {
  // CONST
  const date = new Date()
  const year = date.getFullYear()
  // Hook Assignment
  const [footerData, setFooterData] = React.useState([])
  const [logoData, setLogoData] = React.useState([])
  const [socialData, setSocialData] = React.useState([])
  // UseEffect
  React.useEffect(() => {
    // Footer Data
    const query = '*[_type == "footer"]'
    client.fetch(query)
      .then(data => setFooterData(data))

    // Sanity io Logo Data
    const queryLogo = '*[_type == "companyLogo"]'
    client.fetch(queryLogo)
      .then(data => setLogoData(data))

    const querySocial = '*[_type == "socialMedia"]'
    client.fetch(querySocial)
      .then(data => setSocialData(data))
  }, [])


  // Return component.
  return (
    <div className='app__footer-wrapper'>
      <Link to='/'>
        <div className='app__footer-logoWrapper'>
          <div style={{
            'background': `url(${!_.isEmpty(logoData) && urlFor(logoData[0]?.logo)})`,
            'cursor': 'pointer'
          }} className='app__footer-logo'/>
        </div>
      </Link>
      <div className='app__footer-contentWrapper'>
        <div className='app__footer-wrapper-content'>{footerData && footerData[0]?.contactMode?.map(({ contactInfo }) => (
          <div>{contactInfo}</div>
        ))}
        </div>
        <div className='app__footer-links'><>
          {[
            'home',
            'about_us',
            'services',
            'projects',
            'contact'
          ].map(item => (
            ('home' === item) ? <Link className='links' style={{ 'textDecoration': 'none' }} to='/'>{_.startCase(item)}</Link> : <Link className='links' style={{ 'textDecoration': 'none' }} to={`/${item}`}>{_.startCase(item)}</Link>

          ))}
        </>

        </div>

      </div>
      <div className='app__footer__lower-wrapper'>
        <div className='app__footer__lower-copyrighlinkt'>{footerData && footerData[0]?.companyCopyright} {year}</div>
        <div className='app__footer__lower-social-media'>
          {
            socialData?.map(({ link, socialMediaIcon }) => (
              <a href={link} target='_blank' rel='noreferrer'><div className='socialMediaWrapper'><div className='socialMedia' style={{ 'background': `url(${urlFor(socialMediaIcon)})` }}/></div></a>
            ))
          }
        </div>
      </div>
    </div>
  )
}


/*
 * EXPORTS
 */
export default Index
