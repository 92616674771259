/*
 * IMPORTS
 */
import React from 'react' // NPM: React.js library.
import _ from 'underscore' // NPM: Underscore.js library for  functional suspects (each, map, reduce, filter...) .
import { client } from '../../client' // Helper from sanity.io for data.
import { AiOutlineArrowLeft } from 'react-icons/ai' // NPM: Icons from react-icons.
import { Link, useParams } from 'react-router-dom' // Routing functions from react-router-dom.
import ReactLoading from 'react-loading' // Loading Component from react-loading.
/*
 * STYLES
 */
import './index.scss'


/*
 * COMPONENTS
 */

import { JobOpportunity, QuickApply } from '../../components'

/*
 * CONSTANTS
 */

import { AppWrap, MotionWrap } from '../../wrapper'

/*
 * OBJECTS
 */

const Index = () => {
  const [jobDetail, setJobDetail] = React.useState([])

  const { slug } = useParams()
  React.useEffect(() => {
    localStorage.setItem('slug', JSON.stringify(slug))
    client
      .fetch(`*[slug.current == "${slug}"]`)
      .then(data => setJobDetail(data))
      .catch(console.error)
  }, [slug])

  return (
    _.isEmpty(jobDetail) ? <ReactLoading/> : <>

      <div className='job-apply__main'>
        <Link to='/career'>
          <div className='job-apply__main-buttonWrapper'>
            <button className='job-apply__main-buttonOpening'><AiOutlineArrowLeft/> View All Openings</button>
          </div>
        </Link>
        <div className='job-apply__main-homeWrapper'>
          <button className='job-apply__main-field'>{jobDetail[0].department}</button>
          <div className='job-apply__main-jobProfile'>{jobDetail[0].job_profile}</div>
          <div className='job-apply__main-location'>{jobDetail[0].job_location}</div>
          <div className='job-apply__main-expectation'>{jobDetail[0].expectation}
          </div>
          <a href='#quick_apply'>
            <button className='job-apply__main-apply'>Apply Now</button>
          </a>
        </div>
        <div className='job-apply__main-jobInfoWrapper'>
          <div className='job-apply__main-aboutJobWrapper'>
            <div className='job-apply__main-aboutJobHeading'>
                About this position
            </div>
            <div className='job-apply__main-aboutJob'>
              {jobDetail[0].about_job}
            </div>
            <div className='job-apply__main-pointsWrapper' >
              <div className='job-apply__main-roleWrapper'>
                <div className='job-apply__main-roleHeading'>
                    What are you going to do?
                </div>
                <ul className='job-apply__main-roles'>
                  {
                    jobDetail[0].role.map(({ role_points, _key }) => (
                      <li key={_key}>{role_points}
                      </li>
                    ))

                  }
                </ul>
              </div>
              <div className='job-apply__main-expectation'>
                <div className='job-apply__main-expectationHeading'>
                    You need to have:
                </div>
                <ul className='job-apply__main-expectations'>
                  {
                    jobDetail[0].expectations.map(({ expectation_points, _key }) => (
                      <li key={_key}>{expectation_points}</li>
                    ))
                  }
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className='quick__applyWrapper'>
          <div className='quick__apply'>
            <QuickApply/>
          </div>
        </div>
        <div>
          <JobOpportunity />
        </div>
      </div>
    </>
  )
}

/*
 * EXPORTS
 */
export default AppWrap(
  MotionWrap(Index, 'app__about'),
  'home'
)
