/*
 * IMPORTS
 */
import React from 'react' // NPM: React.js library.
import _ from 'underscore' // NPM: Underscore.js library for  functional suspects (each, map, reduce, filter...) .
import { client, urlFor } from '../../client' // Helper from sanity.io for data.

/*
 * STYLES
 */
import './index.scss'


/*
 * COMPONENTS
 */
import { ServiceContent } from '../../components'

/*
 * CONSTANTS
 */
import { images } from '../../constants'

import { AppWrap, MotionWrap } from '../../wrapper'

/*
 * OBJECTS
 */

const Index = () => {
  // Hook Assignment
  const [serviceData, setServiceData] = React.useState([])

  // UseEffect
  React.useEffect(() => {
    const query = '*[_type == "service"]'
    client.fetch(query).then(data => setServiceData(data))
  }, [])

  return (
      <>
        <div className='service__main'>
          <div className='service__main-homepage'
               style={{ 'background': `url(${!_.isEmpty(serviceData) ? urlFor(serviceData[0]?.serviceBackgroundImage) : images.Placeholder})` }}>
            <div>{'Services'}</div>
          </div>
          <div className='service__wrapper'>
            <div className='services'>
              {!_.isEmpty(serviceData) ? serviceData[0].service_content_heading : ''}
            </div>
            <div className='service__main-point'>
              <ul>
                <li>Feasibility Studies and Options Analysis</li>
                <li>Single Options System</li>
                <li>Conceptual Design Development & Requirement Specifications</li>
                <li>Cost Estimates & Bills of Quantity</li>
                <li>Planning and Programme Integration</li>
                <li>Project Risk Management</li>
              </ul>
              <ul>
                <li>Tendering and Tender Evaluations</li>
                <li>Programme Delivery & Interface Management</li>
                <li>System Integration</li>
                <li>Engineering Assurance Cases & Type Approvals</li>
                <li>Project Engineering and Site Supervision</li>
                <li>T&C (Testing & Commissioning)</li>
              </ul>
            </div>
          </div>
          <ServiceContent serviceData={serviceData}/>

        </div>
      </>
  )
}

/*
 * EXPORTS
 */
export default AppWrap(
    MotionWrap(Index, 'app__about'),
    'home'
)
