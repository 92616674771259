/*
 * IMPORTS
 */
import React from 'react' // NPM: React.js libra
import _ from 'underscore' // NPM: Underscore.js library for  functional suspects (each, map, reduce, filter...) .
import { client, urlFor } from '../../client' // Helper from sanity.io for data.
/*
 * STYLES
 */
import './index.scss'


/*
 * COMPONENTS
 */

import { JobOpportunity } from '../../components'

/*
 * CONSTANTS
 */

import { AppWrap, MotionWrap } from '../../wrapper'
import { images } from '../../constants'

/*
 * OBJECTS
 */

const Index = () => {
  // Hook Assignment
  const arrayImage = []


  const [careerData, setCareerData] = React.useState([])

  // UseEffect
  React.useEffect(() => {
    const query = '*[_type == "career"]'
    client.fetch(query).then(data => setCareerData(data))
  }, [])

  // Breaking Image Data into two parts
  if (!_.isEmpty(careerData)) {
    careerData[0].images.map(item => (
      arrayImage.push(urlFor(item))
    ))
  }
  const half = Math.ceil(arrayImage.length / 2)
  const firstHalf = arrayImage.slice(0, half)
  const secondHalf = arrayImage.slice(half)

  // Return
  return (
    <>
      <div className='career__main'>
        <div className='career__main-headingWrapper'>
          <div className='career__main-heading'>{careerData[0]?.heading}</div>
          <div className='career__main-subHeading'>{careerData[0]?.subHeading}</div>
          <a href='#job_opportunity'><button>View Openings</button></a>
        </div>
        <div className='career__main-imageWrapper'>

          <div className='career__main-imageWrapperPrimary'>
            {
              firstHalf.map((item, index) => (
                <div className='career__main-image' style={{ 'background': `url(${item ? item : images.Placeholder})` }} key={index} />
              ))
            }
          </div>
          <div className='career__main-imageWrapperSecondary'>
            {
              secondHalf.map((item, index) => (
                <div className='career__main-image' style={{ 'background': `url(${item ? item : images.Placeholder})` }} key={index} />
              ))
            }
          </div>
        </div>
        <div className='job__main-wrapper'>
          <JobOpportunity/>
        </div>
      </div>
    </>
  )
}

/*
 * EXPORTS
 */
export default AppWrap(
  MotionWrap(Index, 'app__career'),
  'home'
)
