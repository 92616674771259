/*
 * IMPORTS
 */
import React from 'react' // NPM: React.js library.
import { HiMenuAlt4, HiX } from 'react-icons/hi' // NPM: Icons from react-icons library.
import { AnimatePresence, motion, useCycle } from 'framer-motion' // NPM: smooth motions from framer-motions library.
import _ from 'lodash' // NPM: A modern JavaScript utility library delivering modularity, performance & extras.
import { Link } from 'react-router-dom' // NPM: Link from react-router-dom library.
import { client, urlFor } from '../../client' // Helper from sanity.io for data.

/*
 * STYLES
 */
import './index.scss'

/*
 * OBJECTS
 */
const Index = ({ path }) => {
  // Const assignment.
  const [classNameSet, setClassNameSet] = React.useState('')
  const [logoData, setLogoData] = React.useState([])

  React.useEffect(() => {
    // Sanity io Logo Data
    const query = '*[_type == "companyLogo"]'
    client.fetch(query)
      .then(data => setLogoData(data))
    window.addEventListener('scroll', () => {
      if (0 < window.scrollY) {
        setClassNameSet('scrolled')
      } else {
        setClassNameSet('')
      }
    })
  }, [])

  const homepageLinks = [
    {
      name: 'home', to: '/', id: 1
    },
    {
      name: 'about_us', to: '/about_us', id: 2
    },
    {
      name: 'services', to: '/services', id: 3
    },
    {
      name: 'contact', to: '/contact', id: 4
    },
    {
      name: 'project', to: '/projects', id: 5
    }
  ]


  const [open, cycleOpen] = useCycle(false, true)
  const itemVariants = {
    closed: {
      opacity: 0
    },
    open: { opacity: 1 }
  }

  const sideVariants = {
    closed: {
      transition: {
        staggerChildren: 0.2,
        staggerDirection: -1
      }
    },
    open: {
      transition: {
        staggerChildren: 0.2,
        staggerDirection: 1
      }
    }
  }

  // Return component.
  return (
    <nav className={`app__navbar ${classNameSet}`}>
      <div className='app__navbar-inner'>
        <div>
          <Link to='/'>
            <div className='app__navbar-logo'>
              <img src={!_.isEmpty(logoData) ? urlFor(logoData[0]?.logo) : ''} alt='logo'/>
            </div>
          </Link>
        </div>
        <div>
          <ul className='app__navbar-links'>
            {
              // Navbar Links
              [
                'home',
                'about_us',
                'services',
                'projects',
                'contact'
              ].map(item => (
                ('home' === item) ? <Link style={{ 'textDecoration': 'none' }} to={`/`}>
                  <li className='app__flex p-text' key={`link-${item}`}>
                    {_.startCase(item)}
                  </li>
                </Link> : <Link style={{ 'textDecoration': 'none' }} to={`/${item}`}>
                  <li className='app__flex p-text' key={`link-${item}`}>
                    {_.startCase(item)}
                  </li>
                </Link>
              ))}
          </ul>
        </div>
      </div>
      {
        <div className='app__navbar-menu'>
          <HiMenuAlt4 onClick={cycleOpen}/>
          <AnimatePresence>
            {
              // Mobile view navbar
              open && (
                <motion.aside
                  initial={{ width: 0 }}
                  animate={{
                    width: 300
                  }}
                  exit={{
                    width: 0,
                    transition: {
                      delay: 0.7,
                      duration: 0.3
                    }
                  }}
                >
                  <motion.div
                    className='container'
                    initial='closed'
                    animate='open'
                    exit='closed'
                    variants={sideVariants}
                  >
                    <HiX onClick={cycleOpen}/>
                    <ul>

                      {
                        homepageLinks
                          .map(({
                            name,
                            to,
                            id
                          }) => (
                            <li onClick={cycleOpen}>
                              <motion.a
                                key={id}
                                href={to}
                                whileHover={{ scale: 1.1 }}
                                variants={itemVariants}
                              >
                                <Link to={to}>
                                  {_.startCase(name)}
                                </Link>
                              </motion.a>
                            </li>

                          ))

                      }
                    </ul>
                  </motion.div>
                </motion.aside>
              )}
          </AnimatePresence>

        </div>
      }
    </nav>
  )
}

/*
 * EXPORTS
 */
export default Index
