/*
 * IMPORTS
 */
import React from 'react' // NPM: React.js library.
import { client } from '../../client' // Helper from sanity.io for data.
/*
 * STYLES
 */
import './index.scss'


/*
 * OBJECTS
 */



const Index = () => {
  // Hook Assignment
  const [visionData, setVisionData] = React.useState([])

  // UseEffect
  React.useEffect(() => {
    const query = '*[_type == "vision"]'
    client.fetch(query).then(data => setVisionData(data))
  }, [])

  return (

    <div className='app__main-vision'>
      <div className='heading'>
      Vision
      </div>
      <div className='content'>
        {
          visionData[0] && `“${visionData[0]?.content}“`
        }

      </div>
    </div>
  )
}


/*
 * EXPORTS
 */
export default Index

