/*
 * IMPORTS
 */
import React from 'react' // NPM: react.js library.
import { client, urlFor } from '../../client' // Helper from sanity.io for data.
/*
 * STYLES
 */
import './index.scss'
import { AppWrap, MotionWrap } from '../../wrapper'
import moment from 'moment'
import { images } from '../../constants'


/*
 * OBJECTS
 */

const Index = () => {
  // Hook Assignment
  const [certificateData, setCertificateData] = React.useState([])

  // UseEffect
  React.useEffect(() => {
    const query = '*[_type == "certificate"]'
    client.fetch(query).then(data => setCertificateData(data))
  }, [])
  console.log(certificateData)

  //  Return
  return (
    <div className='certificate__main'>
      <div className='certificate__main_heading'>
            Certificates
      </div>
      <div className='certificate'>
        {
          certificateData?.map((x, index) => (
            <div className='certificate__wrapper'>
              <div style={{ 'background': `url(${x.certificateImage ? urlFor(x.certificateImage) : images.Placeholder})` }} className='certificate__image'/>
              <div className='certificate__date'>
                {moment(x.certificateDate).format('ll')}
              </div>
              <div className='certificate__heading'>
                {x.heading}
              </div>
              <div className='certificate__content'>
                {x.content}
              </div>
            </div>
          ))
        }

      </div>
    </div>
  )
}

/*
 * EXPORTS
 */
export default AppWrap(
  MotionWrap(Index, 'app__certificate'),
  'certificate'
)

