/*
 * IMPORTS
 */
import React from 'react' // NPM: React.js library.
import { client } from '../../client' // Helper from sanity.io for data.

/*
 * STYLES
 */
import './index.scss'

/*
 * COMPONENTS
 */

import { images } from '../../constants'

/*
 * OBJECTS
 */

const Index = () => {
  // Hook Assignment
  const [contactUsData, setContactUsData] = React.useState([])
  // UseEffect
  React.useEffect(() => {
    const query = '*[_type == "contact_us"]'
    client.fetch(query).then(data => setContactUsData(data))
  }, [contactUsData])

  return (
    <>
      {
        <div className='contactInfo__main-wrapper'>
          <div className='contactInfo__main'>
            {
              contactUsData && contactUsData[0]?.contacts.map(({ contact }) => (
                <div className='contactInfo__main-infoWrapper'>
                  <div className='country__headingWrapper'>
                    <div className='country_heading'>
                                                Our {contact.country} Branch
                    </div>
                  </div>
                  {
                    contact.address && <div className='address__wrapper'>
                      <div style={{ 'background': `url(${images.LocationIcon})` }} className='address_icon'/>
                      <div className='address'>{contact.address}</div>
                    </div>
                  }
                  {
                    contact.phone && <div className='phone__wrapper'>
                      <div style={{ 'background': `url(${images.PhoneIcon})` }} className='phone__icon'/>
                      <a href={`tel:${contact.phone}`}><div className='phone'>{contact.phone}</div></a>
                    </div>
                  }
                  {
                    contact.email && <div className='email__wrapper'>
                      <div style={{ 'background': `url(${images.EmailIcon})` }} className='email__icon'/>
                      <a href={`email:${contact.email}`}><div className='email'>{contact.email}</div></a>
                    </div>
                  }
                </div>
              ))

            }
          </div>

        </div>
      }
    </>
  )
}

/*
 * EXPORTS
 */
export default Index
