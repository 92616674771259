/*
 * IMPORTS
 */
import React, { useState } from 'react' // NPM: React.js library.
import { client } from '../../client' // Helper from sanity.io for data.
import { motion } from 'framer-motion' // NPM: framer-motion for motion and animation.
import { Link, useLocation } from 'react-router-dom' // NPM: Routing function from react-router-dom.


/*
 * STYLES
 */
import './index.scss'


/*
 * WRAPPER
 */

import { AppWrap, MotionWrap } from '../../wrapper'

/*
 * OBJECTS
 */

const Index = ({ handle }) => {
  // Path

  const { pathname } = useLocation()

  // Hook Assignment
  const [jobData, setJobData] = React.useState([])
  const [jobDetail, setJobDetail] = React.useState([])

  // UseEffect
  React.useEffect(() => {
    const query = '*[_type == "job_opportunity"]'
    const jobApplyCareer = '*[_type == "job_details"]'
    client.fetch(query).then(data => setJobData(data))
    client.fetch(jobApplyCareer).then(data => setJobDetail(data))
  }, [])

  // Return
  return (
    <>
      <div className='job__main'>
        {
          '/career' === pathname ? <>
            <div className='job__main-heading'>
              {jobData[0]?.heading}
            </div>
            <div className='job__main-subHeading'>
              {jobData[0]?.subHeading}
            </div>
          </> : <>

            <div className='job__main-heading'>
              More Jobs
            </div>
            <div className='job__main-subHeading'>You or your friends may also be interested in</div>
          </>
        }
        <div className='job__main-scroller'>

          {
            jobDetail.map((x, index) => (
              <motion.div

                whileInView={{ opacity: [0, 1] }}
                transition={{ duration: 1, type: 'tween' }}

              >
                <div key={index} className='job__main-opportunityWrapper'>
                  <motion.div
                    whileHover={{
                      scale: 1.02,
                      transition: { duration: 0.5 }
                    }}
                    whileTap={{ scale: 0.98 }}
                  >
                    <div className='job__main-opportunities'>
                      <div className='job__main-opportunitiesContent'>
                        <div className='job__main-department'>
                          {x.department}
                        </div>
                        <div className='job__main-position'>
                          {x.job_profile}
                        </div>
                        <div className='job__main-location'>
                          {x.job_location}
                        </div>
                      </div>
                      <Link to={`/apply/${x.slug.current}`}><button className='job__opening-button' >
                      View Openings
                      </button>
                      </Link>
                    </div>
                  </motion.div>
                </div>
              </motion.div>

            ))
          }

        </div>

      </div>
    </>
  )
}

/*
 * EXPORTS
 */
export default AppWrap(
  MotionWrap(Index, 'app__job_opportunity'),
  'job_opportunity'
)
