/*
 * IMPORTS
 */
import React from 'react' // NPM: React.js library.
import Carousel from 'react-bootstrap/Carousel' // NPM: Carousel from react-bootstrap.
import { client, urlFor } from '../../client' // Helper from sanity.io for data.
/*
 * WRAPPER
 */
import { AppWrap, MotionWrap } from '../../wrapper'


/*
 * STYLES
 */
import './index.scss'
import 'bootstrap/dist/css/bootstrap.css'

/*
 * CONSTANTS
 */

import { images } from '../../constants'

/*
 * OBJECTS
 */
const Index = () => {
  // Hook Assignment
  const [homeData, setHomeData] = React.useState([])

  // UseEffect
  React.useEffect(() => {
    const query = '*[_type == "home"]'
    client.fetch(query).then(data => setHomeData(data))
  }, [])

  // Return
  return (

    <Carousel interval={5000} fade={true} controls={false} pause={false} indicators={false}>
      {
        homeData.map(({ heading, subHeading, homepageImage }, index) => (
          <Carousel.Item key={index} className='item'>
            <div style={{ 'background': `url(${homepageImage ? urlFor(homepageImage) : images.Placeholder})` }} className='home__main'>
              <div className='home__main-shade'>
                <div className='home__headingWrapper'>
                  <div>{heading}</div>
                  <p>{subHeading}</p>
                </div>
              </div>
            </div>
          </Carousel.Item>
        ))
      }

    </Carousel>
  )
}

/*
 * EXPORTS
 */
export default AppWrap(
  MotionWrap(Index, 'app__home'),
  'home'
)
