/*
 * IMPORTS
 */
import React from 'react' // NPM: React.js library.
import { urlFor } from '../../client' // Helper from sanity.io for data.

/*
 * STYLES
 */
import './index.scss'

/*
 * CONSTANTS
 */

import { images } from '../../constants'

/*
 * OBJECTS
 */

const Index = ({ teamData }) => (
  //   Return
  <>
    <div className='aboutContent__main'>
      {
        teamData[0]?.aboutUsPost.map(({ _key, postImage, blogHeading, blogContent }, index) => (
          <div key={_key} className={`${1 === index ? 'aboutContent__main-secondary aboutContent__main-primary' : 'aboutContent__main-primary'}`}>
            <div className='aboutContent__main-imageWrapper'>
              <div className='aboutContent__main-image' style={{ 'background': `url(${postImage ? urlFor(postImage) : images.Placeholder})` }} />
            </div>
            <div className='aboutContent__main-contentWrapper'>
              <div className='aboutContent__main-heading'>
                {blogHeading}
              </div>
              <div className='aboutContent__main-subHeading'>
                {blogContent}
              </div>
            </div>
          </div>

        ))
      }


    </div>
  </>
)

/*
 * EXPORTS
 */
export default Index
