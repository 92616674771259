/*
 * IMPORTS
 */
import React from 'react' // NPM: React.js library.
import { Link } from 'react-router-dom' // NPM: React.js library.
import { client, urlFor } from '../../client' // Helper from sanity.io for data.
/*
 * STYLES
 */
import './index.scss'

/*
 * CONSTANTS
 */

import { AppWrap, MotionWrap } from '../../wrapper'
import { images } from '../../constants'
/*
 * OBJECTS
 */


const Index = () => {
  // Hook Assignment
  const [contactData, setContactData] = React.useState([])

  // UseEffect
  React.useEffect(() => {
    const query = '*[_type == "contact"]'
    client.fetch(query).then(data => setContactData(data))
  }, [])

  // Return
  return (<>
    <div className='contact'>
      {

        contactData.map(({ heading, title, buttonContent, contactImage }, index) => (
          <div className='contact__mainWrapper'>
            <div className='contact__main' key={index}>
              <div className='contact__main-content'>
                <div>
                  {heading}
                </div>
                <div>
                  {title}
                </div>
                <Link to='/contact#home'><button>
                  {buttonContent}
                </button>
                </Link>
              </div>

              <div className='contact__main-image'>
                <div style={{ 'background': `url(${contactImage ? urlFor(contactImage) : images.Placeholder})` }}/>
              </div>
            </div>
          </div>
        ))

      }
    </div>
  </>
  )
}

/*
 * EXPORTS
 */
export default AppWrap(
  MotionWrap(Index, 'app__contact'),
  'contact'
)

