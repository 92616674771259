/*
 * IMPORTS
 */
import React from 'react' // NPM: React.js library.
import { client, urlFor } from '../../client' // Helper from sanity.io for data.
import _ from 'underscore' // NPM: Underscore.js is a utility-belt library for JavaScript.

/*
 * STYLES
 */
import './index.scss'

/*
 * CONSTANTS
 */

import { images } from '../../constants'


/*
 * OBJECTS
 */


const Index = () => {
  // Hook Assignment
  const [capabilitiesData, setCapabilitiesData] = React.useState([])

  // UseEffect
  React.useEffect(() => {
    const query = '*[_type == "capabilities_aboutUs"]'
    client.fetch(query).then(data => setCapabilitiesData(data))
  }, [])

  //   Return
  return (

    <div className='app__main-capabilities'>
      <div style={{ 'background': `url(${!_.isEmpty(capabilitiesData) ? urlFor(capabilitiesData[0]?.image) : images.Placeholder})` }} className='capabilities__main-image'/>
      <div>
        <div className='capability__main-contentWrapper'>
          <div className='heading'>
                Capabilities
          </div>
          <div className='content'>
            {capabilitiesData[0]?.content}
          </div>
        </div>
      </div>
    </div>
  )
}


/*
 * EXPORTS
 */
export default Index

