/*
 * IMPORTS
 */
import React from 'react' // NPM: React.js library.
import Carousel from 'react-bootstrap/Carousel' // NPM: Carousel from react-bootstrap library.
import { Link } from 'react-router-dom' // NPM: Carousel from react-bootstrap.
import { client, urlFor } from '../../client' // Helper from sanity.io for data.

/*
 * STYLES
 */
import './index.scss'
import 'bootstrap/dist/css/bootstrap.css'

/*
 * CONSTANTS
 */

import { images } from '../../constants'

/*
 * OBJECTS
 */

const Index = () => {
  // Hook Assignment
  const [serviceData, setServiceData] = React.useState([])

  // UseEffect
  React.useEffect(() => {
    const query = '*[_type == "services"]'
    client.fetch(query).then(data => setServiceData(data))
  }, [])

  return (
    <>
      <Carousel controls={false} pause='hover' indicators={false} interval={5000}>
        {
          serviceData.map(({ serviceImage, heading, subHeading, buttonHeading }, index) => (
            <Carousel.Item key={index} className='carousel-item' >
              <div className='resources__wrapper'>
                <div className='resources__image' style={{ 'background': `url(${serviceImage ? urlFor(serviceImage) : images.Placeholder})` }}/>
                <div className='resources__contentWrapper'>
                  <div>{heading}</div>
                  <p>{subHeading}</p>
                  <Link to={0 === index ? '/about_us#meet_team' : '/services'}><button>{buttonHeading}</button></Link>
                </div>
              </div>
            </Carousel.Item>
          ))

        }
      </Carousel>
    </>
  )
}

/*
 * EXPORTS
 */
export default Index
