/*
 * IMPORTS
 */
import React from 'react' // NPM: React.js library.
import PropTypes from 'prop-types' // NPM: proptypes library.

/*
 * CONTAINER
 */
import { AboutUs, Contact, Home, Services } from '../../container'


/*
 * OBJECTS
 */
const Index = ({ homeSection, projectSection, aboutSection }) => (
  <>
    <section style={homeSection}>
      <Home/>
    </section>
    <section style={aboutSection}>
      <AboutUs />
    </section>
    <section style={projectSection}>
      <Services/>
    </section>
    <section>
      <Contact/>
    </section>
  </>
)

/*
 * PROPTYPES
 */

Index.propTypes = {
  'navbarSection': PropTypes.object,
  'homeSection': PropTypes.object,
  'projectSection': PropTypes.object,
  'aboutSection': PropTypes.object,
  'contactSection': PropTypes.object
}

Index.defaultProps = {
  'homeSection': {
    'width': '100vw',
    'height': '100vh'
  },
  'aboutSection': {
    'marginTop': '10px'
  }

}

/*
 * EXPORTS
 */
export default Index
