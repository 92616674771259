/*
 * IMPORTS
 */
import React from 'react' // NPM: React.js library.
import { client, urlFor } from '../../client' // Helper from sanity.io for data.
import _ from 'underscore' // NPM: Underscore.js library for  functional suspects (each, map, reduce, filter...) .
/*
 * STYLES
 */
import './index.scss'

/*
 * COMPONENTS
 */

import { ContactForm, ContactInfo } from '../../components'

/*
 * Wrapper
 */

import { AppWrap, MotionWrap } from '../../wrapper'
import { images } from '../../constants'

/*
 * OBJECTS
 */

const Index = () => {
  // Hook Assignment
  const [contactUsData, setContactUsData] = React.useState([])

  // UseEffect
  React.useEffect(() => {
    const query = '*[_type == "contact_us"]'
    client.fetch(query).then(data => setContactUsData(data))
  }, [])

  return (
    <div className='contact__mainWrapper'>
      <div className='contactUs__main'>
        <div className='contactUs__main-homepage' style={ { 'background': `url(${!_.isEmpty(contactUsData) ? urlFor(contactUsData[0]?.contactUsImage) : images.Placeholder})` }}>
          <div className='contactUs__main-homepage-heading'>{contactUsData[0]?.heading}</div>
          <div className='contactUs__main-homepage-subHeading'>{contactUsData[0]?.subHeading}</div>
        </div>
        <div className='contactForm__wrapper'>
          <ContactForm/>
        </div>
        <div className='contactInfo__wrapper'>
          <ContactInfo />
        </div>
      </div>
    </div>
  )
}

/*
 * EXPORTS
 */
export default AppWrap(
  MotionWrap(Index, 'app__contact'),
  'home'
)
