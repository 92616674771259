/*
 * IMPORTS
 */
import React, { useState } from 'react' // NPM: React.js library.
import emailjs from 'emailjs-com' // NPM: emailjs-com library for email conntact.
import { useAlert } from 'react-alert' // NPM: alert from react-alert

/*
 * STYLES
 */
import './index.scss'

/*
 * OBJECTS
 */

const Index = () => {
  // Assign
  const alert = useAlert()
  const form = React.useRef()
  const [loading, setLoading] = useState(false)

  // Email handling
  const sendEmail = e => {
    e.preventDefault()
    setLoading(true)
    emailjs
      .sendForm(
        'service_njn73hn',
        'template_i4yoyw7',
        form.current,
        'X5-1Y5DfVbfdVjq-V'
      )
      .then(
        result => {
          console.log(result.text)
          setLoading(false)

          alert.show('SUCCESS!')
        },
        error => {
          console.log(error.text)
          setLoading(false)
          alert.show('FAILED...', error)
        }
      )
  }

  // Return
  return (
    <>
      <form ref={form} onSubmit={sendEmail}>
        <div className='contactForm__main'>
          <div className='contactForm__main-heading'>
            We would welcome to answer your queries, please contact.
          </div>
          <div className='contactForm__main-inputForm'>
            <div className='contactForm__main-infoWrapper'>
              <div className='contactForm__main-name'>
                <label>Your Name</label>
                <input required name='from_name' placeholder='Your Full Name'/>
              </div>
              <div className='contactForm__main-email'>
                <label>Your Email</label>
                <input required name='email' placeholder='Your Email Address'/>
              </div>
            </div>
            <div className='contactForm__main-messageWrapper'>
              <label>Your Message</label>
              <textarea required name='message' placeholder='Write Here'/>
            </div>
            <div className='contactForm__main-contactButton'>
              <button type='submit'>{loading ? 'Sending' : 'Send'}</button>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

/*
 * EXPORTS
 */
export default Index
