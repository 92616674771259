/*
 * IMPORTS
 */
import React from 'react' // NPM: React.js library.
import _ from 'underscore' // NPM: Underscore.js library for  functional suspects (each, map, reduce, filter...) .
import { client, urlFor } from '../../client' // Helper from sanity.io for data.

/*
 * STYLES
 */
import './index.scss'


/*
 * COMPONENTS
 */
import { AboutUsContent, Capabilities, Incorporation, MeetTeam, Vision } from '../../components'

/*
 * CONSTANTS
 */

import { AppWrap, MotionWrap } from '../../wrapper'
import { images } from '../../constants'

/*
 * OBJECTS
 */

const Index = () => {
  // Hook Assignment
  const [teamData, setTeamData] = React.useState([])

  // UseEffect
  React.useEffect(() => {
    const query = '*[_type == "about_us"]'
    client.fetch(query).then(data => setTeamData(data))
  }, [])

  return (
    <>
      <div className='about__Main'>
        <div className='about__main-homepage' style={{ 'background': `url(${!_.isEmpty(teamData) ? urlFor(teamData[0]?.aboutUsImage) : images.Placeholder})` }}>
          <div>{teamData[0]?.heading}</div>
        </div>
        <AboutUsContent teamData={teamData}/>
        <Vision/>
        <Capabilities/>
        <Incorporation/>
      </div>
    </>
  )
}

/*
 * EXPORTS
 */
export default AppWrap(
  MotionWrap(Index, 'app__about'),
  'home'
)
