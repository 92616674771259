/*
 * IMPORTS
 */
import React from 'react' // NPM: React.js library.
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom' // NPM: proptypes library.
import { Provider as AlertProvider, positions, transitions } from 'react-alert' // NPM: alert from react-alert library.
import AlertTemplate from 'react-alert-template-basic'
import 'bootstrap/dist/css/bootstrap.min.css'

/*
 * COMPONENTS
 */

import { Footer, Navbar } from './components'

/*
 * PAGES
 */

import { About, ContactUs, Error, HomePage, Project, Service } from './pages'
/*
 * STYLES
 */
import './app.scss'

/*
 * OBJECTS
 */
const options = {
  position: positions.TOP_CENTER,
  timeout: 5000,
  offset: '30px',
  transition: transitions.SCALE
}
const Index = () => {
  const location = useLocation()

  return (
    <AlertProvider template={AlertTemplate} {...options}>
      <div className='app'>
        <Navbar path={location.pathname} />
        <Routes>
          <Route path='/' element={<HomePage/>} />
          <Route path='/about_us' element={<About/>} />
          <Route path='/contact' element={<ContactUs/>}/>
          <Route path='/services' element={<Service/>} />
          <Route path='/projects' element={<Project/>} />
          <Route path='*' element={<Error/>}/>

        </Routes>
        <Footer path={location.pathname} />
      </div>
    </AlertProvider>
  )
}

/*
 * EXPORTS
 */
export default Index
