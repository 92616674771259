/*
 * IMPORTS
 */
import React from 'react' // NPM: React.js library.
import { urlFor } from '../../client' // Helper from sanity.io for data.
import { Link } from 'react-router-dom' // Routing functions from react-router-som.
/*
 * STYLES
 */
import './index.scss'
import { images } from '../../constants'
import Carousel from 'react-bootstrap/Carousel'

/*
 * OBJECTS
 */


const Index = ({ serviceData }) => (
  <>
    {
      serviceData[0]?.services.map(({ serviceHeading, service_point, main_content, images: serviceImages }) => (
        <div className='app__main-service'>


          <div className='headingWrapper'>

            <div style={{ 'background': `url(${images.ServiceIcon})` }} className='icon'/>
            <div className='heading'>
              {serviceHeading}
            </div>

          </div>
          <div className='content'>
            {main_content}
          </div>
          <div className='points'>
            <ul>
              {
                service_point.map(({ content }) => (
                  <li>
                    {content}
                  </li>
                ))

              }
            </ul>
            <Carousel interval={5000} fade={false} controls={false} pause={false} indicators={false}>
              {
                serviceImages.map((item, index) => (
                  <Carousel.Item key={index} className='item'>
                    <div style={{ 'background': `url(${item.main_image ? urlFor(item.main_image) : images.Placeholder})` }} className='service__images'/>
                  </Carousel.Item>
                ))
              }
            </Carousel>
          </div>
        </div>
      ))

    }
    <div className='achievement__contentWrapper'>
      <div className='achievement__content'>{serviceData[0]?.achievement}</div>
    </div>
    <Link to='/projects'>
      <div className='buttonWrapper'>
        <button>Know More</button>
      </div>
    </Link>
  </>
)

/*
 * EXPORTS
 */
export default Index
