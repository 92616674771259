/*
 * IMPORTS
 */

import React from 'react' // NPM: React.js library.
import ReactDom from 'react-dom' // NPM: react-dom library.
import { BrowserRouter } from 'react-router-dom' // NPM: proptypes library.

/*
 * MAIN
 */
import App from './app'

/*
 * UTILITY
 */
import ScrollToTop from './utility/ScrollToTop'

/*
 * STYLES
 */
import './index.css'


ReactDom.render(
  <BrowserRouter>
    <ScrollToTop/>
    <App/></BrowserRouter>, document.getElementById('root')
)
