/*
 * IMPORTS
 */
import React from 'react' // NPM: React.js library.
import { motion } from 'framer-motion' // NPM: motion from framer-motion
import { client, urlFor } from '../../client' // Helper from sanity.io for data.
import emailjs from 'emailjs-com' // NPM: email from emailjs-com.
import { useAlert } from 'react-alert' // NPM: alert from react-alert
/*
 * STYLES
 */
import './index.scss'
import { AppWrap, MotionWrap } from '../../wrapper'



/*
 * OBJECTS
 */

const graduation = 31

const Index = () => {
  // Assign
  const alert = useAlert()
  const form = React.useRef()

  // Handle Submit
  const handleSubmit = e => {
    e.preventDefault()
    emailjs
      .sendForm(
        'service_2fm9jpw',
        'template_rt0llug',
        form.current,
        'X5-1Y5DfVbfdVjq-V'
      )
      .then(
        result => {
          console.log(result.text)
          alert.show('SUCCESS!')
        },
        error => {
          console.log(error.text)
          alert.show('FAILED...', error)
        }
      )
  }

  return (
    <div className='app__main-quickApply'>
      <div className='app__main_headingWrapper'>
        <div className='app__main-heading'>
                Quick Apply ⚡️
        </div>
        <div className='app__main-content'>
                JUST ATTACH YOUR CV BELOW AND SEND IT TO US. WE'LL TAKE CARE OF THE REST. WE ENSURE THAT WE REVIEW EVERY
                CV WE RECEIVE.
        </div>
      </div>

      <form ref={form} onSubmit={e => handleSubmit(e)}>
        <div className='app__main-formWrapper'>
          <div className='firstHalf'>
            <div className='inputWrapper_name'>
              <label>FULL NAME*</label>
              <input required type='text' placeholder='Full Name' name='name' />
            </div>
            <div className='inputWrapper_mobile'>
              <label>MOBILE NUMBER*</label>
              <input required type='text' placeholder='Mobile Number' name='mobile'/>
            </div>
            <div className='inputWrapper_graduation'>
              <label>GRADUATION YEAR*</label>
              <select name='graduation'>

                {
                  [...Array(graduation)].map((item, index) => (
                    <option value={2000 + index}>
                      {2000 + index}
                    </option>
                  ))
                }

              </select>
            </div>

          </div>
          <div className='secondHalf'>
            <div className='inputWrapper_email'>
              <label>
                            E-MAIL
              </label>
              <input required type='email' name='email' placeholder='E-mail'/>
            </div>
            <div className='inputWrapper_cv'>
              <label>
                            ATTACH CV*
              </label>
              <input required type='file' name='cv' placeholder='CV'/>
            </div>

          </div>

        </div>
        <div className='button_wrapper'>
          <button className='submit'>Submit</button>
        </div>
      </form>

    </div>
  )
}


/*
 * EXPORTS
 */
export default AppWrap(
  MotionWrap(Index, 'app__quick'),
  'quick_apply'
)

