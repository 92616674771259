/*
 * IMPORTS
 */
import React from 'react' // NPM: react.js library.
import { client, urlFor } from '../../client' // Helper from sanity.io for data.
import { Link } from 'react-router-dom'

/*
 * STYLES
 */
import './index.scss'


/*
 * CONSTANTS
 */

import { images } from '../../constants'
import { AppWrap, MotionWrap } from '../../wrapper'

/*
 * OBJECTS
 */

const Index = () => {
  // Hook Assignment
  const [projectData, setProjectData] = React.useState([])

  // UseEffect
  React.useEffect(() => {
    const query = '*[_type == "completed_project"]'
    client.fetch(query).then(data => setProjectData(data))
  }, [])

  // Return
  return (
    <div className='completed_project__main'>
      <div className='completed_project__main_heading'>
            Completed Projects
      </div>
      <div className='completed_project'>
        {
          projectData?.map((x, index) => (
            <div key={index} className='completed_project__wrapper'>
              <div style={{ 'background': `url(${x.projectImage ? urlFor((x.projectImage)) : images.Placeholder})` }} className='completed_project__image'/>
              <div className='completed_project__date'>
                {x.projectCompletedDate}
              </div>
              <div className='completed_project__heading'>
                {x.heading}
              </div>
              <div className='completed_project__content'>
                {`${x.content.substring(0, 50)}... `}<Link to='/projects' style={{ 'textDecoration': 'none', 'color': '#FF0000' }}>Read more</Link>
              </div>
            </div>
          ))
        }

      </div>
    </div>
  )
}

/*
 * EXPORTS
 */
export default AppWrap(
  MotionWrap(Index, 'app__completed_project'),
  'completed_project'
)

