/*
 * IMPORTS
 */
import React from 'react' // NPM: React.js library.
import Carousel from 'react-bootstrap/Carousel' // NPM: Carousel from react-bootstrap.
/*
 * STYLES
 */
import './index.scss'

/*
 * WRAPPER
 */

import { AppWrap, MotionWrap } from '../../wrapper'

/*
 * COMPONENT
 */
import { Process, Resources } from '../../components'
import { client, urlFor } from '../../client'

/*
 * CONSTANTS
 */

import { images } from '../../constants'

/*
 * OBJECTS
 */

const Index = () => {
  // Hook Assignment
  const [clientData, setClientData] = React.useState([])
  const [Data, setData] = React.useState([])
  // UseEffect
  React.useEffect(() => {
    const query = '*[_type == "client"]'
    const dataQuery = '*[_type == "data_report"]'
    client.fetch(dataQuery).then(data => setData(data))
    client.fetch(query).then(data => setClientData(data))
  }, [])
  console.log(Data)

  // Return
  return (
    <div className='project__main'>
      <Resources/>
      <div className='project__infoWrapper'>
        <div><p>{!_.isEmpty(Data) && Data[0].project_completed}+</p>
          <button>Projects Completed</button>
        </div>
        <div><p>{ !_.isEmpty(Data) && Data[0].countries_supported}+</p>
          <button>Countries Supported</button>
        </div>
        <div><p>{!_.isEmpty(Data) && Data[0].years_experience}+</p>
          <button>Years Experience</button>
        </div>
      </div>
      <div className='project__main-heading'>Our Global Clientele</div>
      <div className='project__main-clientWrapper'>
        {
          clientData.map(({ clientLogo }) => (
            <div className='project__main-client'>
              <div
                style={{ 'background': `url(${clientLogo ? urlFor(clientLogo) : images.Placeholder})` }}/>
            </div>

          ))
        }
      </div>
      <div className='project__main-clientCarousel'>
        <Carousel controls={false} pause={false} indicators={false} interval={2000}>
          {
            clientData.map(({ clientLogo }, index) => (
              <Carousel.Item key={index} className='carousel-item'>
                <div className='project__main-clientCarousel'>
                  <div
                    style={{ 'background': `url(${clientLogo ? urlFor(clientLogo) : images.Placeholder})` }}/>
                </div>
              </Carousel.Item>
            ))
          }
        </Carousel>
      </div>
    </div>
  )
}

/*
 * EXPORTS
 */
export default AppWrap(
  MotionWrap(Index, 'app__home'),
  'services'
)

