/*
 * IMPORTS
 */
import React from 'react' // NPM: React.js library.
import { Rating } from 'react-simple-star-rating' // NPM: star rating from react-simple-star-rating library.
import { GrNext, GrPrevious } from 'react-icons/gr' // NPM: icons from react-icons library.
import { motion } from 'framer-motion' // NPM: icons from react-icons library.
import { client } from '../../client' // Helper from sanity.io for data.
/*
 * STYLES
 */
import './index.scss'


/*
 * CONSTANTS
 */
import { AppWrap, MotionWrap } from '../../wrapper'


/*
 * OBJECTS
 */


const Index = () => {
  // Hook Assignment
  const [testimonialData, setTestimonialData] = React.useState([])

  const [currentIndex, setCurrentIndex] = React.useState(0)

  const handleClick = index => {
    setCurrentIndex(index)
  }

  // UseEffect
  React.useEffect(() => {
    const query = '*[_type == "testimonial"]'
    client.fetch(query).then(data => setTestimonialData(data))
    console.log(testimonialData)
  }, [])

  // Return
  return (
    <div className='testimonial__main'>
      <div className='testimonial__main-header'>
        What people are saying
      </div>
      <div style={{ 'display': 'flex' }}>
        <motion.div
          whileInView={{ opacity: [0, 1] }}
          transition={{ duration: 1, type: 'tween' }}
          key={testimonialData[currentIndex]?._id}
        >
          <div className='testimonial__main-contentWrapper'>
            <div
              className='testimonial__main-content'>“{testimonialData[currentIndex]?.message}”
            </div>
            <div><Rating size='28' initialValue={testimonialData[currentIndex]?.rating } readonly='true'/></div>
            <div className='testimonial__clientWrapper'>
              <div>{ testimonialData[currentIndex]?.name }</div>
              <div>{testimonialData[currentIndex]?.designation}</div>
            </div>
          </div>
        </motion.div>
      </div>
      <div className='pagination-button'>
        <button><GrPrevious
          onClick={() => handleClick(0 === currentIndex ? testimonialData?.length - 1 : currentIndex - 1)}/>
        </button>
        <button><GrNext
          onClick={() => handleClick(currentIndex === testimonialData?.length - 1 ? 0 : currentIndex + 1)}/>
        </button>
      </div>

    </div>
  )
}

/*
 * EXPORTS
 */
export default AppWrap(
  MotionWrap(Index, 'app__testimonial'),
  'testimonial'
)

