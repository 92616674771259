/*
 * IMPORTS
 */
import React from 'react' // NPM: React.js library.
import { client, urlFor } from '../../client' // Helper from sanity.io for data.
import { Link } from 'react-router-dom' // NPM: Link from react-router-dom .
/*
 * STYLES
 */
import './index.scss'

/*
 * COMPONENT
 */
import { CompletedProject } from '../../components'



/*
 * CONSTANTS
 */

import { images } from '../../constants'
import { AppWrap, MotionWrap } from '../../wrapper'

/*
 * OBJECTS
 */

const Index = () => {
  // Hook Assignment
  const [aboutData, setAboutData] = React.useState([])

  // UseEffect
  React.useEffect(() => {
    const query = '*[_type == "about"]'
    client.fetch(query).then(data => setAboutData(data))
  }, [])

  // Return
  return (
    < >
      {
        aboutData.map(({ title, heading, subHeading, aboutImage }, index) => (
          <div className='about__mainWrapper' key={index}>
            <div className='about__main'>
              <div className='about__main-content'>
                <div>{title}</div>
                <div>{heading}</div>
                <div>{subHeading}</div>
                <Link to={'/about_us'}><button>Learn More</button></Link>
              </div>
              <div className='about__main-image' style={{ 'background': `url(${aboutImage ? urlFor(aboutImage) : images.Placeholder})` }}/>
            </div>
            <div style={{ 'marginTop': '140px' }}>
              <CompletedProject/>
            </div>
          </div>
        ))

      }

    </>
  )
}

/*
 * EXPORTS
 */
export default AppWrap(
  MotionWrap(Index, 'app__about_us'),
  'about_us'
)

